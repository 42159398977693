import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationServiceService } from './../../services/notification-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-generator-component',
  templateUrl: './password-generator-component.component.html',
  styleUrls: ['./password-generator-component.component.css']
})
export class PasswordGeneratorComponentComponent implements OnInit {

  language: string = "es";

  password: string = '';

  passwordLengths: any = [];
  passwordStrengthless: number = 0;

  form: any = FormGroup;
  letrasMinusculas: boolean = true;
  letrasMayusculas: boolean = true;
  numeros: boolean = true;
  simbolos: boolean = true;
  longitud: string = '12';
  ambiguos: boolean = true;

  minusculasArray = ['q','w','e','r','t','y','u','i','o','p','a','s','d','f','g','h','j','k','l','z','x','c','v','b','n','m'];
  mayusculasArray = ['Q','W','E','R','T','Y','U','I','O','P','A','S','D','F','G','H','J','K','L','Z','X','C','V','B','N','M'];
  numerosArray = [0,1,2,3,4,5,6,7,8,9];
  simbolosArray = ['@','#','$','%','&','=','*','-','_','.',',',';',':'];
  ambiguosArray = ['¡','!','¿','?','(',')','/','\\','\'','"','[',']','{','}'];

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private notificationService: NotificationServiceService, private translate: TranslateService) { }

  ngOnInit(): void {
    var lng = this.route.snapshot.paramMap.get("lng");
    if((lng === 'es') || (lng === 'en') || (lng === 'ca')){
      this.language = lng;
    }
    this.selectLanguage();
    this.generatePasswordLengths();
    this.form = this.fb.group({
      longitud: [this.longitud, [Validators.required]],
      letrasMinusculas: [this.letrasMinusculas, [Validators.required]],
      letrasMayusculas: [this.letrasMayusculas, [Validators.required]],
      numeros: [this.numeros, [Validators.required]],
      simbolos: [this.simbolos, [Validators.required]],
      ambiguos: [this.ambiguos, [Validators.required]],
    });
    this.form.statusChanges.subscribe(() => {
      this.generarContrasenya();
    });
    this.generarContrasenya();
  }

  generatePasswordLengths() {
    var minimum = 1;
    var maximum = 2048;
    this.passwordLengths = [];
    for(var i= minimum; i<=maximum; i++){
      this.passwordLengths.push(i);
    }
  }

  generarContrasenya() {
    var use: any = [];
    var long = this.form.get('longitud').value;
    if(this.form.get('letrasMinusculas').value === true){
      use.push.apply(use, this.minusculasArray);
    }
    if(this.form.get('letrasMayusculas').value === true){
      use.push.apply(use, this.mayusculasArray);
    }
    if(this.form.get('numeros').value === true){
      use.push.apply(use, this.numerosArray);
    }
    if(this.form.get('simbolos').value === true){
      use.push.apply(use, this.simbolosArray);
    }
    if(this.form.get('ambiguos').value === true){
      use.push.apply(use, this.ambiguosArray);
    }
    use = this.shuffle(use);

    var passwordGenerated = '';
    if(use.length > 0){
      for(var i=1; i<=long; i++){
        var random = this.randomNumber(1,(use.length-1));
        passwordGenerated = passwordGenerated + use[random];
      }
    }

    this.password = passwordGenerated;
    this.setPasswordStrong(passwordGenerated);
  }

  shuffle(a: any) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  randomNumber(min: number, max: number){
    const r = Math.random()*(max-min) + min;
    return Math.ceil(r);
  }

  setPasswordStrong(password: string) {
    var score = 0;
    if (!password){
      this.passwordStrengthless = score;
    }

    var letters: any = new Object();
    for (var i=0; i<password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }

    var variations: any = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    }

    var variationCount = 0;
    for (var check in variations) {
      variationCount += (variations[check] == true) ? 1 : 0;
    }
    score += (variationCount - 1) * 2;

    this.passwordStrengthless = Math.ceil(score);
  }

  showMessage(){
    this.notificationService.openSnackBar('🚀 ' + this.translate.instant('generator.copied'));
  }

  changeType(type: string){
    if(type === 'pin'){
      this.form.controls['longitud'].setValue(4);
      this.longitud = '4';
      this.form.controls['letrasMinusculas'].setValue(false);
      this.form.controls['letrasMayusculas'].setValue(false);
      this.form.controls['numeros'].setValue(true);
      this.form.controls['simbolos'].setValue(false);
      this.form.controls['ambiguos'].setValue(false);
    }else if(type === 'password'){
      this.form.controls['longitud'].setValue(12);
      this.longitud = '12';
      this.form.controls['letrasMinusculas'].setValue(true);
      this.form.controls['letrasMayusculas'].setValue(true);
      this.form.controls['numeros'].setValue(true);
      this.form.controls['simbolos'].setValue(true);
      this.form.controls['ambiguos'].setValue(true);
    }
  }

  selectLanguage(){
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
  }

}
