<div class='body'>
  <div class='content'>

    <mat-card class='passCard'>
      <div class='passwordGenerated'>
        <input type="text" class='inputShowPassword' [(ngModel)]="password">
      </div>
      <div [ngClass]="{'danger-bar': passwordStrengthless < 40, 'warn-bar': passwordStrengthless > 40, 'ok-bar': passwordStrengthless > 60 }">
        <mat-progress-bar class="strongPasswordBar" mode="determinate" [value]="passwordStrengthless"></mat-progress-bar>
      </div>
    </mat-card>

    <div class='buttons'>
      <mat-form-field appearance="fill" class='selectType'>
        <mat-label>{{ 'generator.selectType' | translate }}</mat-label>
        <mat-select value='password' (selectionChange)="changeType($event.value)">
          <mat-option value='password'>{{ 'generator.password' | translate }}</mat-option>
          <mat-option value='pin'>{{ 'generator.pin' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-fab color="accent" class='reloadButton' (click)='generarContrasenya()'><mat-icon>loop</mat-icon></button>
      <button mat-raised-button color="accent" class='saveButton' [cdkCopyToClipboard]="password" (click)="showMessage()"><mat-icon>content_copy</mat-icon> {{ 'generator.copy' | translate }}</button>
    </div>

    <mat-card class='passSettings'>
      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'generator.length' | translate }}</mat-label>
          <mat-select [(value)]="longitud" placeholder="Longitud" formControlName="longitud">
            <mat-option *ngFor="let i of passwordLengths" value='{{i}}'>{{i}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class='blockForm'><mat-slide-toggle [checked]="letrasMinusculas" color='accent' formControlName="letrasMinusculas">{{ 'generator.useLowercase' | translate }}</mat-slide-toggle><mat-icon class='cursorInfo' matTooltip="q w e r t y u i o p a s d f g h j k l z x c v b n m">info</mat-icon></div>
        <div class='blockForm'><mat-slide-toggle [checked]="letrasMayusculas" color='accent' formControlName="letrasMayusculas">{{ 'generator.useUppercase' | translate }}</mat-slide-toggle><mat-icon class='cursorInfo' matTooltip="Q W E R T Y U I O P A S D F G H J K L Z X C V B N M">info</mat-icon></div>
        <div class='blockForm'><mat-slide-toggle [checked]="numeros" color='accent' formControlName="numeros">{{ 'generator.useNumbers' | translate }}</mat-slide-toggle><mat-icon class='cursorInfo' matTooltip="0 1 2 3 4 5 6 7 8 9">info</mat-icon></div>
        <div class='blockForm'><mat-slide-toggle [checked]="simbolos" color='accent' formControlName="simbolos">{{ 'generator.useSymbols' | translate }}</mat-slide-toggle><mat-icon class='cursorInfo' matTooltip="@ # $ % & = * - _ . ; :">info</mat-icon></div>
        <div class='blockForm'><mat-slide-toggle [checked]="ambiguos" color='accent' formControlName="ambiguos">{{ 'generator.useAmbiguous' | translate }}</mat-slide-toggle><mat-icon class='cursorInfo' matTooltip='¡ ! ¿ ? ( ) / \ &apos; " [ ] { }'>info</mat-icon></div>
      </form>
    </mat-card>

  </div>
</div>
