import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PasswordGeneratorComponentComponent } from './pages/password-generator-component/password-generator-component.component';

const routes: Routes = [
  { path: '', redirectTo: 'password-generator', pathMatch: 'full' },
  { path: 'password-generator/:lng', component: PasswordGeneratorComponentComponent, pathMatch: 'full'},
  { path: '**', component: PasswordGeneratorComponentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
